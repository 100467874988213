
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class SearchCard extends Vue {
  @Prop()
  value!: string;

  get search() {
    return this.value;
  }
  
  set search(value) {
    this.$emit('input', value);
  }
}
