
import Vue from 'vue';
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator';
import { mapGetters } from "vuex";

import ImageCard from '@/components/cards/ImageCard.vue';
import SearchCard from '@/components/cards/SearchCard.vue';
import NotFoundCard from '@/components/cards/NotFoundCard.vue';

import { langs } from '@/constants';
import { Location } from '@/entities/Location';
import { Service } from '@/entities/directory/Service';

@Component({
  computed: mapGetters([
    'location'
  ]),

  components: {
    ImageCard,
    SearchCard,
    NotFoundCard,
  }
})
export default class ServicesListView extends Vue {
  location!: Location;

  services: Service[] = [];

  langs = langs;
  lang = langs[0].abbr;
  
  search = "";

  mounted() {
    this.fetchServices();
  }

  @Watch('location')
  fetchServices() {
    if (this.location.uuid === undefined) return;

    this.$services.listByLocation(this.location).then((services) => {
      this.services = services;
    });
  }

  get filtered() {
    let filtered = this.services.filter((service) => {
      return service.title.toLowerCase().includes(this.search.trim().toLowerCase());
    });

    filtered = filtered.filter((service) => {
      return service.lang === this.lang;
    });

    return filtered;
  }
}
