
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class ImageCard extends Vue {
  @Prop({ required: true })
  onClick!: Function;

  @Prop({ required: true })
  imageUrl!: string;

  @Prop({ required: true })
  title!: string;
}
