// Categories for Images
const categories = [
  'Banner',
  'Logo',
  'Service',
  'Food',
  'Restaurant',
  // 'Logo Restaurant',
  // 'Banner Restaurant'
];

// Available deplacements in our app
const deplacements = [
  { text: 'Walking', abbr: 'walking' },
  { text: 'Driving', abbr: 'driving'}
]

// Categories for snacks
const snacksCategories = [
  "share",
  "drinks",
  "healthy",
  "kids",
  "desserts"
];

// Types for snacks
const snacksTypes = [
  "food",
  "drink"
];

// Categories for food
const foodCategories = [
  "share",
  "entree",
  "kids",
  "drinks",
  "main",
  "desserts",
];

// Types for food
const foodTypes = [
  "food",
  "drink"
];

// Types for hotel
const hotelTypes = [
  "hotel",
  "nearby"
]

// Available languaages in our app
const langs = [
  { text: 'French', abbr: 'fr' },
  { text: 'English', abbr: 'en'}
]

// Types of buttons available for the homescreen
const buttonTypes = [
  "weather",
  "discover",
  "services",
  "music",
  "cleanup",
  "alfred",
  "image",
  "notification",
  "cast"
];

// Types of banner available for the homescre
const bannerTypes = [
  'tv',
  'image',
  'video'
]

export {
  categories,
  snacksCategories,
  snacksTypes,
  foodCategories,
  foodTypes,
  hotelTypes,
  langs,
  buttonTypes,
  bannerTypes,
  deplacements,
}
